import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import '../styles/layout.css';

const openNav = () => {
  document.getElementById('myNav').style.width = '100%';
};

const closeNav = () => {
  document.getElementById('myNav').style.width = '0%';
};

class Header extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <script
            src="https://kit.fontawesome.com/af7fc3d4ab.js"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <div id="myNav" class="overlay">
          <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>
            &times;
          </a>

          <div class="overlay-content">
            <Link to="/">NL</Link>
            <Link to="/ENG/">ENG</Link>
            <Link to="/FR">FR</Link>
            <Link to="/ESP">ESP</Link>
          </div>
        </div>

        <div class="topnav">
          <div class="topnav-centered">
            <Link class="active">
              <h1
                style={{
                  fontFamily: 'Playfair Display',
                  fontWeight: '700',
                  letterSpacing: '0.5em',
                  fontSize: '26px',
                  textTransform: 'uppercase'
                }}
                className="is-size-3 is-hidden-mobile"
              >
                B & D Audrey Fromont
              </h1>
            </Link>
          </div>

          <a class="hideMe" onClick={openNav}>
            <i class="fas fa-bars"></i>
          </a>

          <div class="topnav-right">
            <Link style={{ fontSize: '12px' }} className="nav-link" to="/">
              NL
            </Link>
            <Link style={{ fontSize: '12px' }} className="nav-link" to="/ENG">
              ENG
            </Link>
            <Link style={{ fontSize: '12px' }} className="nav-link" to="/FR">
              FR
            </Link>
            <Link style={{ fontSize: '12px' }} className="nav-link" to="/ESP">
              ESP
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
