import React from 'react';
import '../../node_modules/bulma/css/bulma.css';

export default () => (
  <div>
    <nav className="level">
      <p className="level-item has-text-centered footerText">
        AUDREY FROMONT - ASTENE (DEINZE) - +32(0)475 29 20 28 -
        AUDREY.FROMONT@GMAIL.COM
      </p>
    </nav>
  </div>
);
