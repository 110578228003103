import React from 'react';
import { Form, Button } from 'react-bootstrap';
import '../../src/styles/layout.css';

export default props => (
  <div>
    <Form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/thanks"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <Form.Label>{props.firstLabel}</Form.Label>
      <Form.Control size="sm" name="naam" type="text" />
      <Form.Label>{props.secondLabel}</Form.Label>
      <Form.Control size="sm" name="email" type="email" />
      <Form.Group controlId="contactFormulier.ControlSelect">
        <Form.Label name="soort klant">{props.selectLabel}</Form.Label>
        <Form.Control size="sm" name="soort klant" as="select">
          <option>{props.firstOption}</option>
          <option>{props.secondOption}</option>
          <option>{props.thirdOption}</option>
          <option>{props.fourthOption}</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="contactFormulier.ControlTextarea">
        <Form.Label>{props.thirdLabel}</Form.Label>
        <Form.Control name="bericht" type="text" as="textarea" rows="3" />
      </Form.Group>
      <Button
        style={{ textTransform: 'uppercase', fontSize: '12px' }}
        variant="dark"
        type="submit"
      >
        {props.btnLabel}
      </Button>
    </Form>
  </div>
);
