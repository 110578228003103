import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import '../styles/layout.css';
import favicon from '../../static/favicon.jpg';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';

const Layout = props => {
  return (
    <div>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Playfair+Display&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div className="fullPage">
        <div className="content">
          <Header />
          {props.children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
